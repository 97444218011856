<!--
 * @Description: 系统管理 App管理 资讯管理 informationManagement
 * @Author: zhoucheng
 * @Github: https://github.com/hzylyh
 * @Date: 2021-04-06 14:05:15
 * @LastEditors: zhoucheng
-->
<template>
  <div class='mainBody'>
    <!--上半部查询-->
    <el-row class="topSelectTwoLine">
      <el-form :model="searchForm"
               :inline="true">
        <el-row class="topFirstLine">
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="资讯名称">
              <el-input v-model="searchForm.contentName"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="资讯状态">
              <el-select v-model="searchForm.contentStatus"
                         placeholder="请选择">
                <el-option v-for="item in options"
                           :key="item.value"
                           :label="item.name"
                           :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="topButton">
          <el-button type="primary"
                     @click="onSubmit">查 询</el-button>
          <el-button type="info"
                     @click="resetForm"
                     style="margin-left:17px;">重 置</el-button>
        </el-row>
      </el-form>

    </el-row>
    <el-row class="tableRow">
      <el-row class="tableTitle">
        <span>资讯列表</span>
        <el-button class="tableTitleButton"
                   type="success"
                   @click="add">新 增</el-button>
      </el-row>
      <el-row class="tableContent">
        <!-- 表格 -->
        <dt-table :tableColumnList="tableColumnList"
                  :data="tableList"
                  :map="listMap"
                  ref="tableRef"
                  :tableConfig='tableConfig'
                  :paginationConfig='paginationConfig'
                  @getList="getList">
          <template slot="operating">
            <el-table-column label="操作"
                             class-name='class-name'>
              <template slot-scope="scope">
                <el-col :span="8">
                  <el-button type="text"
                             size="medium"
                             @click="edit(scope.row)">编辑</el-button>
                </el-col>
                <el-col :span="8">
                  <el-button type="text"
                             size="medium"
                             @click="Delete(scope.row)">删除</el-button>
                </el-col>
              </template>
            </el-table-column>
          </template>
        </dt-table>
      </el-row>
    </el-row>
    <!-- 弹出框 -->
    <el-dialog :title="this.dialogTitle"
               :visible.sync="informationVisible"
               append-to-body
               @close="popClose">
      <el-form :model="form"
               ref="form"
               :rules="rules"
               :inline="true"
               size="small">
        <el-row justify="space-around">
          <el-col :span="12">
            <el-form-item label="资讯名称"
                          prop="contentName"
                          label-width="90px">
              <el-input v-model="form.contentName"
                        placeholder="请输入"
                        class="dt-form-width"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label-width="90px"
                          label="资讯状态"
                          prop="contentStatus">
              <el-select v-model="form.contentStatus"
                         placeholder="请选择"
                         class="dt-form-width">
                <el-option v-for="item in options"
                           :key="item.value"
                           :label="item.name"
                           :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row justify="space-around">

          <el-form-item label="资讯内容"
                        prop="content"
                        label-width="90px">
            <richText ref="richText"
                      v-model="form.content"
                      :height="220"
                      :uploadImgMaxLength="6"
                      @uploadImg="uploadImg"></richText>
          </el-form-item>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="图片"
                          label-width="90px">
              <el-upload v-model="form.url"
                         list-type="picture-card"
                         :limit='1'
                         :auto-upload="true"
                         :http-request='submitUpload'
                         :file-list="fileList"
                         action=""
                         ref="upload">
                <i class="el-icon-plus"></i>
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="备注"
                          prop="remark"
                          label-width="90px">
              <el-input v-model="form.remark"
                        placeholder="请输入"
                        class="dt-form-width"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row type="flex"
              justify="end">
        <el-col :span="12"
                class="dt-button-box">
          <el-button type="primary"
                     @click="confirm('form')"
                     style="margin-left:17px;
                     border-radius:4px;
                     height:36px;">确 定</el-button>
          <el-button @click="informationVisible = false"
                     type="info">取 消</el-button>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import dtTable from '@/components/table.vue'
import richText from "@/components/richText";
import Compressor from 'compressorjs'
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    dtTable,
    richText
  },
  data () {
    //这里存放数据
    return {
      //分页
      paginationConfig: { layout: 'prev,sizes,pager,next,jumper', total: 0 },
      tableConfig: { border: true },
      listMap: {
        pageNum: 1,
        pageSize: 15,
      },
      //表头
      tableColumnList: [
        {
          prop: 'contentId',
          label: '资讯编号',
        },
        {
          prop: 'contentName',
          label: '资讯名称',
        },
        {
          prop: 'status',
          label: '资讯状态',
        },
        {
          prop: 'content',
          label: '资讯内容',
        },
        {
          prop: 'createTime',
          label: '创建时间',
        },
      ],
      //表格内容
      tableList: {
        list: [],
      },
      //搜索表单
      searchForm: {
        contentName: '',//资讯名称
        contentStatus: '',//资讯状态
      },
      pageNum: 1,
      pageSize: 15,
      options: [],//下拉框列表
      // 新增表单 
      form: {
        contentName: '',
        content: '',
        contentStatus: '',
        remark: '',
        url: ''
      },
      informationType: '',//弹出框类型 0为新增，1未修改
      dialogTitle: '',//弹出框标题
      informationVisible: false,//弹出框 
      fileList: [{ url: '' }],//上传文件
      rules: {
        contentName: [
          { required: true, message: '请输入资讯名称', trigger: 'blur' },
          { min: 3, max: 50, message: '长度在 3 到 10 个字符', trigger: 'blur' }
        ],
        content: [
          { required: true, message: '请输入资讯内容', trigger: 'blur' },
          { min: 5, max: 200, message: '长度在 5 到 20 个字符', trigger: 'blur' }
        ],
        contentStatus: [
          { required: true, message: '请选择资讯状态', trigger: 'change' }
        ],
      },
      Pager: null,//获取分页器
    }
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created () { },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    //获取分页器组件
    this.Pager = this.$refs.tableRef.$children[1]
    this.initialization()
    this.advisoryManagementBoxQuery()
  },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
  //方法集合
  methods: {
    //初始化页面数据
    initialization () {
      this.searchForm['pageNum'] = this.pageNum;
      this.searchForm['pageSize'] = this.pageSize;
      this.$informationManagement.queryContentList(this.searchForm).then(response => {
        let list = response.resultEntity.list
        this.tableList.list = list
        this.paginationConfig.total = response.resultEntity.total
      })
    },
    //分页
    getList (change) {
      this.pageSize = change.pageSize
      this.pageNum = change.pageNum
      this.initialization()
    },
    //查询
    onSubmit () {
      this.Pager.internalCurrentPage = 1
      this.listMap.pageNum = 1
      this.pageNum = 1
      this.initialization()
    },

    //重置
    resetForm () {
      this.searchForm = {}
      this.listMap.pageNum = 1
      this.pageNum = 1
      this.Pager.internalCurrentPage = 1
      this.initialization()
    },

    //新增接口
    new () {
      this.$informationManagement.addContent(this.form).then(response => {
        if (response.resultCode == 2000) {
          this.$message({ message: '新增成功', type: 'success' });
        }
        this.initialization()
      })
    },
    //新增
    add () {
      this.form = {
        contentName: '',
        content: '',
        contentStatus: '',
        remark: '',
      }
      // 设置富文本默认内容
      this.$nextTick(() => {
        this.$refs.richText.setValue('')
      })
      this.fileList = []
      this.informationVisible = true;
      this.dialogTitle = '新增资讯'
      this.informationType = 0
    },
    //富文本图片上传
    uploadImg (resultFiles, insertImgFn) {
      resultFiles.forEach(file => {
        // 压缩图片
        const self = this
        new Compressor(file, {
          quality: 0.2,
          success (result) {
            // 将 Blob 对象转换成 File 对象
            result = new window.File([result], result.name, { type: result.type })
            const formData = new FormData()
            formData.append('file', result)
            self.$queryDict.upFile(formData).then(res => {
              insertImgFn(self.$imgBaseUrl + res.resultEntity)
            })
          },
          error () {
          }
        })
      })
    },
    //修改接口
    update () {
      this.$informationManagement.updateContent(this.form).then(response => {
        if (response.resultCode === 2000) {
          this.$message({ message: '修改成功', type: 'success' });
        }
        this.initialization()
      })
    },
    //修改
    edit (row) {
      this.fileList = [{ url: "" }]
      this.informationVisible = true;
      this.dialogTitle = '修改资讯'
      this.informationType = 1
      this.fileList[0].url = this.$imgBaseUrl + row.url
      this.form = this.$deepClone(row)
      // 设置富文本默认内容
      this.$nextTick(() => {
        this.$refs.richText.setValue(row.content)
      })
    },
    //弹出框确认
    confirm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.informationType === 0) {
            this.new()
          } else if (this.informationType === 1) {
            this.update()
          }
          this.informationVisible = false;
        } else {
          return false;
        }
      });
    },
    //删除接口
    Delete (row) {
      this.$confirm('确认删除该条记录?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        let info = { "contentId": row.contentId }
        this.$informationManagement.delAdvertise(info).then(() => {
          this.$message({ message: '删除成功', type: 'success' });
          this.initialization()
        })
      })
    },
    // 清空验证
    popClose () {
      this.$refs.form.resetFields();
    },
    // 上传图片
    submitUpload (file) {
      // 压缩图片
      const self = this
      new Compressor(file.file, {
        quality: 0.2,
        success (result) {
          // 将 Blob 对象转换成 File 对象
          result = new window.File([result], result.name, { type: result.type })
          const formData = new FormData()
          formData.append('file', result)
          self.$queryDict.upFile(formData).then(res => {
            self.form.url = res.resultEntity
          }).catch(() => {
          })
        },
        error () {
        }
      })
    },
    //咨询管理下拉框
    advisoryManagementBoxQuery () {
      let info = {
        "columnName": [
          "value",
          "name"
        ],
        "tableName": "tb_dict",
        "whereStr": [
          {
            "colName": "parent_id",
            "value": "BB31X414V24X44BV44BV12424124BVCB"
          }
        ]
      }
      this.$queryDict.queryDict(info).then(response => {
        this.options = response.resultEntity
      })
    },
  },
}
</script>
<style lang='scss' scoped>
$topSelectHeight: 135px;
.mainBody {
  height: 100%;
  // 顶部查询条件
  .topSelectTwoLine {
    width: 100%;
    min-height: $topSelectHeight;
    @include background('blockBg');
    padding-top: 24.3px;
    padding-left: 21px;
    .topSecondLine {
      margin-top: 19.7px;
    }
    .topButton {
      display: flex;
      padding-left: 91px;
      margin-top: 17px;
    }
  }
  .tableRow {
    width: 100%;
    @include background('blockBg');
    margin-top: 16px;
    padding-left: 20.2px;
    padding-right: 20.2px;
    padding-top: 5px;
    .tableTitle {
      height: 32px;
      margin-top: 15px;
      .tableTitleButton {
        float: right;
      }
    }
    .tableContent {
      margin-top: 10px;
    }
  }
}
.el-button--success {
  background: #019fe4;
}
.dt-button-box {
  display: flex;
  justify-content: flex-end;
}
.dt-form-width {
  width: 230px;
}
.dt-form-width {
  width: 230px;
}
</style>


